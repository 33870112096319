<template>
  <div>
    <b-container class="no-gutters bg-white py-3">
      <b-row class="align-items-center justify-content-between">
        <b-col >
          <b-input-group>
            <b-form-input
              type="search"
              class="search-bar"
              id="header-search-bar"
              @keyup.enter="handleSearch"
              :placeholder="placeholder"
              v-model="filter.search"
              v-debounce:200ms="handleSearch"
            >
            </b-form-input>
            <b-input-group-append is-text class="mr-2">
              <b-iconstack
                font-scale="2"
                type="submit"
                @click="handleSearch"
              >
                <b-icon stacked icon="search" scale="0.5" variant="grey"></b-icon>
              </b-iconstack>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col class="text-right">
          <b-button @click.prevent="sidebarFilter" class="btn-filter ml-2">
            <font-awesome-icon icon="filter" class="text-black d-sm-none" />
            <span class="d-none d-sm-block">
              <font-awesome-icon icon="filter" class="pointer" />
              Filter
            </span>
          </b-button>
          <button
            type="button"
            class="btn button btn-mobile ml-2"
            @click.prevent="exportExcel"
          >
            <font-awesome-icon icon="file-export" class="text-black d-sm-none" />
            <span class="d-none d-sm-block">
              <font-awesome-icon icon="file-export" class="pointer" />
              Export
            </span>
          </button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    filter: {
      required: true,
      type: Object,
    },
    placeholder: {
      required: true,
      type: String,
    },
  },
  methods: {
    handleSearch() {
      this.filter.page = 1;
      this.filter.redeem_date = null;
      this.filter.used_date = null;
      this.$emit("handleSearch", this.filter);
    },
    exportExcel() {
      this.$emit("exportExcel");
    },
    sidebarFilter() {
      this.$emit("sidebarFilter");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
::v-deep .btn-filter button,
.btn-filter {
  color: var(--primary-color) !important;
  border-color: var(--primary-color);
  font-weight: 100;
}
::v-deep .form-control:disabled,
.form-control[readonly] {
  background-color: #fafafa;
}
.form-control:focus {
  border-color: var(--primary-color)
}
input#header-search-bar::placeholder {
  text-transform: capitalize;
}
.input-group > .input-group-append:not(:last-child) > .input-group-text {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
</style>
